
export const minigramPageList = [
  {
    "path": "pages/mall/mall",
    "name": "商城页",
  },{
    "path": "pages/shop/shop",
    "name": "门店页",
  },{
    "path": "pages/shop/drink",
    "name": "好茶页",
  },{
    "path": "subPackages/mine/vip-card-charge/vip-card-charge",
    "name": "钱包充值页",
  }
]

export const otherMinipageList = [
  {
    "appId": "wx07029b11aae46e2a",
    "path": "pages/home/index",
    "name": "宠物小程序-主页",
  }
]


export function isOrderNo (val) {
  // 2023032110149623
  return /^\d{16}$/.test(val || "") // 16位纯数字
}

export function isSubOrderNo (val) {
  // S201020250654
  // return /^S\d{12}$/i.test(val || "") // 13位长度
  return /^S\d+$/i.test(val || "") // S开头不限长度
}